export const documentationPages: DocumentationPage[] = [
    {
        "title": "Guide",
        "description": "",
        "slug": "guide",
        "show": true,
        "markdown": "",
        "alerts": [],
        "children": [
            {
                "title": "Requesting an Event",
                "description": "How to request to use Compass at your event.",
                "slug": "requesting-an-event",
                "show": true,
                "markdown": "Compass is a request-based event platform. To get started, you will need to make\na request to use Compass at your event. To do this, visit the\n[Pricing](/pricing) page and select the plan that best fits your needs. Once you\nhave selected a plan, you will be prompted to fill out a form with information\nabout your event. Once you have submitted the form, we will review your request.\n\nRequests are typically reviewed within 24 hours. Once your request has been\napproved, you will receive an email notification and will see your event\nconnected to your account.\n",
                "alerts": [
                    {
                        "message": "This page is a work in progress and may be missing information.",
                        "severity": "info"
                    }
                ]
            },
            {
                "title": "Configuration",
                "description": "How to configure your event.",
                "slug": "configuration",
                "show": true,
                "markdown": "",
                "alerts": [
                    {
                        "message": "This page is a work in progress and may be missing information.",
                        "severity": "info"
                    }
                ]
            },
            {
                "title": "Manage Users",
                "description": "How to manage users in Compass.",
                "slug": "manage-users",
                "show": true,
                "markdown": "",
                "alerts": [
                    {
                        "message": "This page is a work in progress and may be missing information.",
                        "severity": "info"
                    }
                ]
            },
            {
                "title": "During your Event",
                "description": "What can you do during your event.",
                "slug": "during-your-event",
                "show": true,
                "markdown": "",
                "alerts": [
                    {
                        "message": "This page is a work in progress and may be missing information.",
                        "severity": "info"
                    }
                ]
            },
            {
                "title": "After your Event",
                "description": "Wrap up your event with surveys and analytics.",
                "slug": "after-your-event",
                "show": true,
                "markdown": "",
                "alerts": [
                    {
                        "message": "This page is a work in progress and may be missing information.",
                        "severity": "info"
                    }
                ]
            }
        ]
    },
    {
        "title": "Account Merge",
        "description": "merge multiple accounts into one.",
        "slug": "account-merge",
        "show": true,
        "markdown": "If you have multiple accounts and would like to merge them, please contact support ([hello@compass-ep.com](mailto:hello@compass-ep.com)) with the following information:\n\n1. The email address of the account you would like to keep.\n2. The email address of the account you would like to merge.\n\n> __NOTE__ _We may need to verify your identity before merging accounts._\n",
        "alerts": []
    },
    {
        "title": "Analytics",
        "description": "",
        "slug": "analytics",
        "show": false,
        "markdown": "",
        "alerts": [
            {
                "message": "This page is a work in progress and may be missing information.",
                "severity": "info"
            }
        ]
    },
    {
        "title": "Calendar",
        "description": "",
        "slug": "calendar",
        "show": false,
        "markdown": "",
        "alerts": [
            {
                "message": "This page is a work in progress and may be missing information.",
                "severity": "info"
            }
        ]
    },
    {
        "title": "Custom pages",
        "description": "",
        "slug": "custom-pages",
        "show": false,
        "markdown": "",
        "alerts": [
            {
                "message": "This page is a work in progress and may be missing information.",
                "severity": "info"
            }
        ]
    },
    {
        "title": "Event Groups",
        "description": "",
        "slug": "event-groups",
        "show": false,
        "markdown": "",
        "alerts": [
            {
                "message": "This page is a work in progress and may be missing information.",
                "severity": "info"
            }
        ]
    },
    {
        "title": "Events",
        "description": "",
        "slug": "events",
        "show": false,
        "markdown": "",
        "alerts": [
            {
                "message": "This page is a work in progress and may be missing information.",
                "severity": "info"
            }
        ]
    },
    {
        "title": "Notifications",
        "description": "",
        "slug": "notifications",
        "show": false,
        "markdown": "",
        "alerts": [
            {
                "message": "This page is a work in progress and may be missing information.",
                "severity": "info"
            }
        ]
    },
    {
        "title": "Offline Mode",
        "description": "",
        "slug": "offline-mode",
        "show": false,
        "markdown": "",
        "alerts": [
            {
                "message": "This page is a work in progress and may be missing information.",
                "severity": "info"
            }
        ]
    },
    {
        "title": "User Connections",
        "description": "",
        "slug": "user-connections",
        "show": false,
        "markdown": "",
        "alerts": [
            {
                "message": "This page is a work in progress and may be missing information.",
                "severity": "info"
            }
        ]
    },
    {
        "title": "Users",
        "description": "",
        "slug": "users",
        "show": false,
        "markdown": "",
        "alerts": [
            {
                "message": "This page is a work in progress and may be missing information.",
                "severity": "info"
            }
        ]
    }
];