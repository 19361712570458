import { SvgIconComponent } from "@mui/icons-material";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import GroupIcon from "@mui/icons-material/Group";
import ViewDayIcon from "@mui/icons-material/ViewDay";

export interface Feature {
    title: string;
    description: string;

    beta: boolean;

    learn_more_url?: string;

    image: string;
    svg_icon: SvgIconComponent;
}

export const large_features: Omit<Feature, "svg_icon">[] = [
    {
        title: "Offline-First Design",
        description: "Built to support natively support low-to-no internet connectivity. Allow users to use your app offline and sync when they're back online.",
        image: "/img/feature1.png",
        beta: true,
    },
    {
        title: "Notifications",
        description: "Send notifications to your users to keep them engaged and informed.",
        image: "/img/feature2.png",
        beta: true,
    },
    {
        title: "Custom Pages",
        description: "Create custom pages to display information to your users. Add an event program or a list of speakers.",
        image: "/img/feature3.png",
        beta: true,
    },
];

export const small_features: Omit<Feature, "image">[] = [
    {
        title: "Calendar",
        description: "Built-in calendar integration for displaying your event schedule.",
        svg_icon: ViewDayIcon,
        beta: true,
    },
    {
        title: "Groups",
        description: "Group users together to manage them more easily.",
        svg_icon: GroupIcon,
        beta: true,
    },
    {
        title: "Analytics",
        description: "Gain insights into your event with analytics.",
        svg_icon: AnalyticsIcon,
        beta: true,
    },
];
