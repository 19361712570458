import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import SiteStateContextProvider from "./SiteStateContext";
import theme from "./theme";
import ReactGA from "react-ga4";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

ReactGA.initialize("G-6V83Q3MN03");

root.render(
    <React.StrictMode>
        <SiteStateContextProvider>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <App />
            </ThemeProvider>
        </SiteStateContextProvider>
    </React.StrictMode>
);
