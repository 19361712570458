import { createBrowserRouter, RouteObject, RouterProvider } from "react-router-dom";
import { documentationPages } from "./data/docs";
import Layout from "./Layout";
import { PrivacyPolicy } from "./md/privacy-policy";
import { TermsOfService } from "./md/terms-of-service";
import ComingSoon from "./pages/ComingSoon";
import DocumentationList from "./pages/DocumentationList";
import Features from "./pages/Features";
import Landing from "./pages/Landing";
import MarkdownPage from "./pages/MarkdownPage";
import PageNotFound from "./pages/PageNotFound";
import Pricing from "./pages/Pricing";
import Request from "./pages/Request";
import DocumentationLayout from "./pages/DocumentationLayout";

const COMING_SOON = process.env.NODE_ENV !== "development";

// Recursively build the tree from the documentation pages
function buildTree(pages: RouteObject[], docs: DocumentationPage[], url_prefix: string) {
    docs.forEach((doc) => {
        if (doc.children) {
            buildTree(pages, doc.children, `${url_prefix}${doc.slug}/`);
        }
        pages.push({
            path: `${url_prefix}${doc.slug}`,
            element: <MarkdownPage markdown={doc.markdown} title={doc.title} alerts={doc.alerts} />,
        });
    });
}

const doc_routes: RouteObject[] = [];

buildTree(doc_routes, documentationPages, "/docs/");

function App() {
    const router = createBrowserRouter([
        {
            path: "/",
            element: COMING_SOON ? <ComingSoon /> : <Layout />,
            children: [
                { path: "/", element: <Landing /> },
                { path: "/features", element: <Features /> },
                { path: "/pricing", element: <Pricing /> },

                { path: "/request", element: <Request /> },

                // Legal
                { path: "/privacy-policy", element: <MarkdownPage markdown={PrivacyPolicy} title={"Privacy Policy"} /> },
                { path: "/terms-of-service", element: <MarkdownPage markdown={TermsOfService} title={"Terms of Service"} /> },

                {
                    path: "/docs",
                    element: <DocumentationLayout />,
                    children: [{ path: "/docs", element: <DocumentationList /> }, ...doc_routes],
                },

                // 404
                { path: "*", element: <PageNotFound /> },
            ],
            // errorElement: <div>404 Not Found</div>,
        },
    ]);

    return <RouterProvider router={router} />;
}

export default App;
