import { Box, Grid } from "@mui/material";
import { RichTreeView } from "@mui/x-tree-view/RichTreeView";
import { TreeViewBaseItem } from "@mui/x-tree-view/models";
import React from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { documentationPages } from "../data/docs";

const tree: TreeViewBaseItem[] = [
    {
        id: "/docs",
        label: "Compass EP Documentation",
        children: [],
    },
];

// Recursively build the tree from the documentation pages
function buildTree(pages: TreeViewBaseItem[], docs: DocumentationPage[], url_prefix: string) {
    docs.forEach((doc) => {
        const children: TreeViewBaseItem[] = [];
        if (doc.children) {
            buildTree(children, doc.children, `${url_prefix}${doc.slug}/`);
        }
        pages.push({
            id: `${url_prefix}${doc.slug}`,
            label: doc.title,
            children,
        });
    });
}

buildTree(
    tree,
    documentationPages.filter((d) => d.show),
    "/docs/"
);

// Recursively search for all ancestors of a given item
function getAncestors(item: TreeViewBaseItem, tree: TreeViewBaseItem[], ancestors: TreeViewBaseItem[] = []): TreeViewBaseItem[] | null {
    for (const child of tree) {
        if (child.id === item.id) {
            return [...ancestors, child];
        }
        if (child.children) {
            const result = getAncestors(item, child.children, [...ancestors, child]);
            if (result) {
                return result;
            }
        }
    }
    return null;
}

export default function DocumentationLayout() {
    const navigate = useNavigate();
    const location = useLocation();

    const [selectedItem, setSelectedItem] = React.useState<string>(location.pathname);
    const [expandedItems, setExpandedItems] = React.useState<string[]>([]);
    const [hideSidebarOnMobile, setHideSidebarOnMobile] = React.useState<boolean>(location.pathname === "/docs");

    React.useEffect(() => {
        setSelectedItem(location.pathname);

        // Hide the sidebar on mobile when the user is on the docs page
        setHideSidebarOnMobile(location.pathname === "/docs");

        const ancestors = getAncestors({ id: location.pathname, label: "" }, tree);
        if (ancestors) {
            setExpandedItems(ancestors.map((a) => a.id));
        }
    }, [location.pathname]);

    const handleItemSelectionToggle = (event: React.SyntheticEvent, itemId: string, isSelected: boolean) => {
        setSelectedItem(itemId);
        navigate(itemId);
    };

    const handleExpandedItemsChange = (event: React.SyntheticEvent, newExpandedItems: string[]) => {
        setExpandedItems(newExpandedItems);
    };

    return (
        <Grid container sx={{ pt: 3 }}>
            <Grid item xs={12} sm={5} md={4} lg={3} sx={{ p: 1, display: { xs: hideSidebarOnMobile ? "none" : "block", sm: "block" } }}>
                <Box sx={{ minHeight: 200, minWidth: 300, flexGrow: 1 }}>
                    <RichTreeView items={tree} onItemSelectionToggle={handleItemSelectionToggle} selectedItems={selectedItem} expandedItems={expandedItems} onExpandedItemsChange={handleExpandedItemsChange} />
                </Box>
            </Grid>
            <Grid item xs={12} sm={7} md={8} lg={9} sx={{ p: 2 }}>
                <Outlet />
            </Grid>
        </Grid>
    );
}
