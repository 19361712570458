import { red } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";

export const COLORS = {
    LAPIS: "#336699",
    CAROLINE_BLUE: "#86BBD8",
    CHARCOAL: "#2F4858",
    LIGHT_GREEN: "#9EE493",
    NYANZA: "#DAF7DC",
    BACKGROUND: "#F5F5F5",
};

// A custom theme for this app
const theme = createTheme({
    palette: {
        mode: "light",
        background: {
            default: COLORS.BACKGROUND,
        },
        primary: {
            main: COLORS.LAPIS,
        },
        secondary: {
            main: COLORS.CAROLINE_BLUE,
        },
        success: {
            main: COLORS.LIGHT_GREEN,
        },
        // error: {
        //     main: red.A400,
        // },
    },
    typography: {
        // h1: {
        //     fontSize: "2.5rem",
        // },
    },
    // typography: {
    //     fontFamily: "MuseoSans300",
    //     h1: {
    //         fontFamily: "MuseoSlab700",
    //     },
    //     h2: {
    //         fontFamily: "MuseoSlab300",
    //     },
    //     h3: {
    //         fontFamily: "MuseoSans700",
    //     },
    //     body1: {
    //         fontFamily: "MuseoSans300",
    //     },
    //     body2: {
    //         fontFamily: "MuseoSans300",
    //     },
    // },
});

export default theme;
