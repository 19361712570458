import { Search } from "@mui/icons-material";
import { Box, Container, Grid, Grow, InputAdornment, Link, Paper, TextField, Typography } from "@mui/material";
import React, { useContext, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import { SiteStateContext } from "../SiteStateContext";
import { documentationPages } from "../data/docs";
import { COLORS } from "../theme";

// Convert documentation pages to flat list
const flatDocs: DocumentationPage[] = [];
function flattenDocs(docs: DocumentationPage[], url_prefix:string) {
    docs.forEach((doc) => {
        const doc2 = structuredClone(doc);
        doc2.slug = url_prefix + doc2.slug;
        flatDocs.push(doc2);
        if (doc.children) {
            flattenDocs(doc.children, url_prefix + doc.slug + "/");
        }
    });
}
flattenDocs(documentationPages, "/docs/");

// Sort flat docs by title
flatDocs.sort((a, b) => a.title.localeCompare(b.title));

export default function DocumentationList() {
    const { setSiteState } = useContext(SiteStateContext);

    const [search, setSearch] = React.useState("");
    const [filteredDocs, setFilteredDocs] = React.useState<DocumentationPage[]>([]);

    useEffect(() => {
        if (setSiteState) {
            setSiteState({ title: "Docs", hideFooter: false });
        }
    }, []);

    useEffect(() => {
        let found: DocumentationPage[] = [];

        // Search by title first
        found.push(...flatDocs.filter((doc) => doc.title.toLowerCase().includes(search.toLowerCase())));

        // Search by description second
        found.push(...flatDocs.filter((doc) => doc.description.toLowerCase().includes(search.toLowerCase())));

        // Search by body third
        found.push(...flatDocs.filter((doc) => doc.markdown.toLowerCase().includes(search.toLowerCase())));

        // Dedupe
        found = found.filter((doc, idx) => found.indexOf(doc) === idx);

        setFilteredDocs(found);
    }, [search]);

    return (
        <Box>
            <Grid container spacing={2}>
                <Grid item xs={12} md={7}>
                    <Typography variant="h2" color={COLORS.LAPIS}>
                        Docs
                    </Typography>
                    <Typography variant="h4" color={COLORS.CAROLINE_BLUE}>
                        Documentation articles and guides.
                    </Typography>
                </Grid>
                <Grid item xs={12} md={5}>
                    <Box>
                        <TextField
                            variant="outlined"
                            fullWidth
                            size="small"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Search />
                                    </InputAdornment>
                                ),
                            }}
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            placeholder="Search docs..."
                        />
                    </Box>
                </Grid>
            </Grid>
            <Grid container spacing={3} sx={{ mt: 3 }}>
                {filteredDocs.map((doc, idx) => (
                    <Grid item key={idx} xs={12} sm={6} md={4}>
                        <Grow in timeout={150 * (idx + 1)}>
                            <Link to={doc.slug} underline="none" component={RouterLink}>
                                <Paper sx={{ p: 3, height: "100%" }}>
                                    <Typography variant="h5" color={COLORS.LAPIS}>
                                        {doc.title}
                                    </Typography>
                                    <Typography variant="body1" color={COLORS.CAROLINE_BLUE}>
                                        {doc.description}
                                    </Typography>
                                </Paper>
                            </Link>
                        </Grow>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
}
