export interface PricingPlan {
    name: string;
    price: number;
    user_limit: number;
}

export const plans: PricingPlan[] = [
    {
        name: "Free",
        price: 0,
        user_limit: 50,
    },
    {
        name: "Small",
        price: 5,
        user_limit: 100,
    },
    {
        name: "Medium",
        price: 15,
        user_limit: 200,
    },
    {
        name: "Large",
        price: 40,
        user_limit: 500,
    },
];
