import { Box, Button, Container, Grid, Grow, Link, Slide, Stack, Typography, useMediaQuery } from "@mui/material";
import { useContext, useEffect, useRef } from "react";
import { Link as RouterLink } from "react-router-dom";
import { SiteStateContext } from "../SiteStateContext";
import theme, { COLORS } from "../theme";

export default function Landing() {
    const { setSiteState } = useContext(SiteStateContext);

    const containerRef = useRef<HTMLElement>(null);

    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    useEffect(() => {
        if (setSiteState) {
            setSiteState({ title: "", hideFooter: false });
        }
    }, []);

    return (
        <Box>
            <Container maxWidth="md">
                <Box sx={{ minHeight: "60vh", mt: { xs: "5vh", sm: "15vh" } }}>
                    <Box>
                        <Grid container>
                            <Grid item xs={12} md={6} sx={{ pt: { xs: 0, sm: 5 } }}>
                                <Typography variant="h2" color={COLORS.LIGHT_GREEN} display="inline">
                                    Connect
                                </Typography>
                                <Typography variant="h2" color={COLORS.LAPIS} display="inline">
                                    {" "}
                                    your event{" "}
                                </Typography>
                                <Typography variant="h2" color={COLORS.LIGHT_GREEN} display="inline">
                                    together
                                </Typography>
                                <Typography variant="h2" color={COLORS.LAPIS} display="inline">
                                    .
                                </Typography>
                                <Stack sx={{ mt: 3 }} direction={"row"} spacing={2}>
                                    <Grow in timeout={{ appear: 200, enter: 600 }}>
                                        <Link to="/request" component={RouterLink}>
                                            <Button variant="contained">
                                                <Typography variant="body1" color={"#fff"}>
                                                    Get Started
                                                </Typography>
                                            </Button>
                                        </Link>
                                    </Grow>
                                    <Grow in timeout={{ appear: 200, enter: 600 }}>
                                        <Link to="/features" component={RouterLink}>
                                            <Button variant="outlined">
                                                <Typography variant="body1" color={COLORS.LAPIS}>
                                                    See features
                                                </Typography>
                                            </Button>
                                        </Link>
                                    </Grow>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} md={6} sx={{ maxHeight: 400, overflow: "hidden", pt: { xs: 6, sm: 0 } }}>
                                <Box ref={containerRef}>
                                    <Slide in container={containerRef.current} timeout={{ appear: 200, enter: 600 }} direction="up" easing={"cubic-bezier(0.0, 0, 0.2, 1)"}>
                                        {/* TODO: Use actual app screenshot */}
                                        <Box component={"img"} src="/img/placeholder_phone.png" sx={{ width: "75%", mx: "auto", display: "block" }} />
                                    </Slide>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
                {/* <Box sx={{ height: "100vh" }}></Box> */}
            </Container>
        </Box>
    );
}
