import { Alert, Container, Link, Typography } from "@mui/material";
import { useContext, useEffect } from "react";
import Markdown from "react-markdown";
import { SiteStateContext } from "../SiteStateContext";
import { Link as RouterLink } from "react-router-dom";

export interface MarkdownAlert {
    message: string;
    severity: "success" | "info" | "warning" | "error";
}

export default function MarkdownPage({ markdown, title, alerts }: { markdown: string; title: string; alerts?: MarkdownAlert[] }) {
    const { setSiteState } = useContext(SiteStateContext);

    useEffect(() => {
        if (setSiteState) {
            setSiteState({ title, hideFooter: false });
        }
    }, [title]);

    return (
        <Container maxWidth="md">
            <Typography variant="h2" gutterBottom>
                {title}
            </Typography>
            {alerts &&
                alerts.map((alert, idx) => (
                    <Alert key={idx} severity={alert.severity}>
                        {alert.message}
                    </Alert>
                ))}
            <Markdown
                components={{
                    a: ({ node, ...props }) => (
                        <Link component={RouterLink} to={props.href as string} target="_blank">
                            {props.children}
                        </Link>
                    ),
                }}
            >
                {markdown}
            </Markdown>
        </Container>
    );
}
