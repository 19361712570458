import { Box, Button, Container, Link, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { COLORS } from "../theme";

export default function PageNotFound() {
    return (
        <Container maxWidth="md" sx={{ pt: 8 }}>
            <Typography variant="h2" color={COLORS.LAPIS}>
                Oops!
            </Typography>
            <Typography variant="h4" color={COLORS.CAROLINE_BLUE}>
                We couldn't find that page.
            </Typography>
            <Box sx={{ mt: 5 }}>
                <Link to="/" component={RouterLink} underline="none">
                    <Button variant="contained" color="inherit" sx={{ backgroundColor: COLORS.NYANZA, color: "#000" }}>
                        Go back home
                    </Button>
                </Link>
            </Box>
        </Container>
    );
}
