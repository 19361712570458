import { Box, Button, Container, Input, LinearProgress, MenuItem, Select, Stack, Step, StepLabel, Stepper, TextField, Typography } from "@mui/material";
import { useContext, useEffect, useReducer, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { plans } from "../data/plans";
import React from "react";
import { SiteStateContext } from "../SiteStateContext";

interface RequestPostParams {
    // contact details
    requestor_name: string;
    requestor_email: string;

    // event details
    event_name: string;
    event_description: string;
    event_start: string;
    event_end: string;
    event_location: string;

    // plan limit
    event_estimated_attendance: string;
}

export default function Request() {
    const { setSiteState } = useContext(SiteStateContext);

    const [searchParams, setSearchParams] = useSearchParams();

    const [variant, setVariant] = useState(searchParams.get("variant") || plans[0].name.toLowerCase());

    const [activeStep, setActiveStep] = useState(0);

    const [errorText, setErrorText] = useState("");

    const [state, update] = useReducer(
        (state: RequestPostParams, action: Partial<RequestPostParams>) => {
            return { ...state, ...action };
        },
        { requestor_name: "", requestor_email: "", event_name: "", event_description: "", event_start: "", event_end: "", event_location: "", event_estimated_attendance: "" }
    );

    useEffect(() => {
        if (setSiteState) {
            setSiteState({ title: "Event Request", hideFooter: false });
        }
    }, []);

    useEffect(() => {
        setSearchParams({ variant });

        update({ event_estimated_attendance: plans.find((p) => p.name.toLowerCase() === variant)?.user_limit.toString() || "" });
    }, [variant, setSearchParams]);

    useEffect(() => {
        if (activeStep === 3) {
            console.log("submitting", state);

            fetch(`${process.env.REACT_APP_API_BASE}/event-request`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(state),
            })
                .then((res) => {
                    if (res.ok) {
                        setActiveStep(4);
                    } else {
                        console.error(res);
                        setActiveStep(5);
                    }
                })
                .catch((e) => {
                    console.error(e);
                    setActiveStep(5);
                });
        }
    }, [activeStep]);

    const plan = plans.find((p) => p.name.toLowerCase() === variant);

    if (!plan) {
        setVariant("free");
        return;
    }

    return (
        <Container maxWidth="md" sx={{ mt: 8 }}>
            <Stepper activeStep={activeStep}>
                <Step>
                    <StepLabel>Plan</StepLabel>
                </Step>
                <Step>
                    <StepLabel>Contact Details</StepLabel>
                </Step>
                <Step>
                    <StepLabel>Event Details</StepLabel>
                </Step>
            </Stepper>
            {activeStep === 5 ? (
                <React.Fragment>
                    <Typography sx={{ mt: 2, mb: 1 }}>An error occurred while submitting your request. Please try again later.</Typography>
                    <Button onClick={() => setActiveStep(3)}>Try Again</Button>
                </React.Fragment>
            ) : activeStep === 4 ? (
                <React.Fragment>
                    <Typography sx={{ mt: 2, mb: 1 }}>Thank you for your request! We will review your submission and get back to you shortly.</Typography>
                </React.Fragment>
            ) : activeStep === 3 ? (
                <React.Fragment>
                    <LinearProgress sx={{ mt: 5 }} />
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <Box sx={{ p: 3 }}>
                        {activeStep === 0 && (
                            <Box>
                                <Typography variant="h5" sx={{ mb: 3 }}>
                                    Plan
                                </Typography>

                                <Select value={variant} onChange={(e) => setVariant(e.target.value)} sx={{ minWidth: "50%" }} variant={"standard"}>
                                    {plans.map((p) => (
                                        <MenuItem key={p.name} value={p.name.toLowerCase()}>
                                            {p.name} (${p.price} for {p.user_limit} users)
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Box>
                        )}
                        {activeStep === 1 && (
                            <Box>
                                <Typography variant="h5" sx={{ mb: 3 }}>
                                    Contact Details
                                </Typography>

                                <Stack direction={"column"} spacing={3}>
                                    <TextField
                                        placeholder="Name"
                                        value={state.requestor_name}
                                        onChange={(e) => {
                                            update({ requestor_name: e.target.value });
                                        }}
                                        required
                                    />
                                    <TextField type="email" placeholder="Email" value={state.requestor_email} onChange={(e) => update({ requestor_email: e.target.value })} />
                                </Stack>
                            </Box>
                        )}
                        {activeStep === 2 && (
                            <Box>
                                <Typography variant="h5" sx={{ mb: 3 }}>
                                    Event Details
                                </Typography>

                                <Stack direction={"column"} spacing={3}>
                                    <TextField placeholder="Event Name" value={state.event_name} onChange={(e) => update({ event_name: e.target.value })} />
                                    <TextField placeholder="Event Description" multiline value={state.event_description} onChange={(e) => update({ event_description: e.target.value })} />
                                    <TextField placeholder="Event Location" value={state.event_location} onChange={(e) => update({ event_location: e.target.value })} />
                                    <TextField type="datetime-local" placeholder="Event Start" value={state.event_start} onChange={(e) => update({ event_start: e.target.value })} />
                                    <TextField type="datetime-local" placeholder="Event End" value={state.event_end} onChange={(e) => update({ event_end: e.target.value })} />
                                </Stack>
                            </Box>
                        )}
                    </Box>
                    <Typography variant="body2" color="red" sx={{ mx: 3, py: 1 }}>
                        {errorText}
                    </Typography>
                    <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                        <Button
                            color="inherit"
                            disabled={activeStep === 0}
                            onClick={() => {
                                setErrorText("");
                                setActiveStep(activeStep - 1);
                            }}
                            sx={{ mr: 1 }}
                        >
                            Back
                        </Button>
                        <Box sx={{ flex: "1 1 auto" }} />
                        <Button
                            onClick={() => {
                                if (activeStep === 0) {
                                    if (!variant) {
                                        setErrorText("Please select a plan");
                                        return;
                                    }
                                }

                                if (activeStep === 1) {
                                    if (!state.requestor_name || !state.requestor_email) {
                                        setErrorText("Please fill out all fields");
                                        return;
                                    }
                                }

                                if (activeStep === 2) {
                                    if (!state.event_name || !state.event_description || !state.event_start || !state.event_end || !state.event_location || !state.event_estimated_attendance) {
                                        setErrorText("Please fill out all fields");
                                        return;
                                    }
                                }

                                setErrorText("");
                                setActiveStep(activeStep + 1);
                            }}
                        >
                            {activeStep === 2 ? "Finish" : "Next"}
                        </Button>
                    </Box>
                </React.Fragment>
            )}
        </Container>
    );
}
