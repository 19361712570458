import { Button, Container, Grid, Grow, Link, Paper, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { plans } from "../data/plans";
import { COLORS } from "../theme";
import { useContext, useEffect } from "react";
import { SiteStateContext } from "../SiteStateContext";

export default function Pricing() {
    const { setSiteState } = useContext(SiteStateContext);

    useEffect(() => {
        if (setSiteState) {
            setSiteState({ title: "Pricing", hideFooter: false });
        }
    }, []);

    return (
        <Container sx={{ pt: 6 }}>
            <Typography variant="h2" color={"#336699"}>
                Pricing
            </Typography>
            <Typography variant="h4" color={"#86BBD8"}>
                Choose a plan that fits your needs.
            </Typography>

            <Grid container spacing={3} sx={{ mt: 3 }}>
                {plans.map((plan, idx) => (
                    <Grid item key={plan.name} xs={12} sm={6} md={3}>
                        <Grow in timeout={250 * (idx + 1)}>
                            <Paper sx={{ p: 3 }}>
                                <Typography variant="h3" color={COLORS.LAPIS}>
                                    {plan.name}
                                </Typography>
                                <Typography variant="h4" color={COLORS.LIGHT_GREEN}>
                                    ${plan.price}
                                </Typography>
                                <Typography variant="body1" color={COLORS.CHARCOAL}>
                                    Up to {plan.user_limit} users
                                </Typography>

                                <Link to={`/request?variant=${plan.name.toLowerCase()}`} underline="none" component={RouterLink}>
                                    <Button variant="contained" color="primary" fullWidth sx={{ mt: 5 }}>
                                        Request
                                    </Button>
                                </Link>
                            </Paper>
                        </Grow>
                    </Grid>
                ))}
            </Grid>
        </Container>
    );
}
