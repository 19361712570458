import { Box, Chip, Container, Grid, Grow, Link, Slide, Stack, Typography } from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import { SiteStateContext } from "../SiteStateContext";
import { large_features, small_features } from "../data/features";
import { COLORS } from "../theme";

function LargeFeature({ feature, idx }: { feature: (typeof large_features)[0]; idx: number }) {
    const containerRef = useRef<HTMLElement>(null);

    return (
        <Box ref={containerRef} sx={{ overflowX: "hidden" }}>
            <Grid container direction={idx % 2 ? "row-reverse" : "row"} alignItems={"center"} spacing={3}>
                <Grid item key={idx} xs={12} sm={6}>
                    <Slide in container={containerRef.current} timeout={{ appear: 200, enter: 400 * (idx + 1) }} direction={idx % 2 ? "left" : "right"} easing={"ease-out"}>
                        <Box sx={{ p: 3, height: "100%" }}>
                            <img src={feature.image} alt={feature.title} style={{ width: "100%" }} />
                        </Box>
                    </Slide>
                </Grid>
                <Grid item key={idx} xs={12} sm={6}>
                    <Slide in container={containerRef.current} timeout={{ appear: 200, enter: 400 * (idx + 1) }} direction={idx % 2 ? "right" : "left"} easing={"ease-out"}>
                        <Box sx={{ p: 3, height: "100%" }}>
                            <Stack direction={"row"} alignItems={"center"} spacing={2}>
                                <Typography variant="h5" color={COLORS.LAPIS}>
                                    {feature.title}
                                </Typography>
                                {feature.beta && <Chip label="Beta" color="success" size="small" />}
                            </Stack>
                            <Typography variant="body1" sx={{ my: 3, width: "75%" }}>
                                {feature.description}
                            </Typography>
                            {feature.learn_more_url && (
                                <Link href={feature.learn_more_url} target="_blank" rel="noreferrer">
                                    Learn more
                                </Link>
                            )}
                        </Box>
                    </Slide>
                </Grid>
            </Grid>
        </Box>
    );
}

function SmallFeature({ feature, idx, delay }: { feature: (typeof small_features)[0]; idx: number; delay: number }) {
    const [show, setShow] = useState(false);

    useEffect(() => {
        const timeout = setTimeout(() => setShow(true), delay);
        return () => clearTimeout(timeout);
    }, [delay]);

    const timeout = 300 * (idx + 1);

    return (
        <Grow in={show} timeout={timeout}>
            <Box sx={{ p: 3, height: "100%" }}>
                <Stack direction={"row"} alignItems={"center"} spacing={2}>
                    <feature.svg_icon fontSize="large" sx={{ color: COLORS.LAPIS }} />
                    <Typography variant="h5" color={COLORS.LAPIS}>
                        {feature.title}
                    </Typography>
                    {feature.beta && <Chip label="Beta" color="success" size="small" />}
                </Stack>
                <Typography variant="body1">{feature.description}</Typography>
                {feature.learn_more_url && (
                    <Link href={feature.learn_more_url} target="_blank" rel="noreferrer">
                        Learn more
                    </Link>
                )}
            </Box>
        </Grow>
    );
}

export default function Features() {
    const { setSiteState } = useContext(SiteStateContext);

    const containerRef = useRef<HTMLElement>(null);

    useEffect(() => {
        if (setSiteState) {
            setSiteState({ title: "Features", hideFooter: false });
        }
    }, []);

    return (
        <Container sx={{ pt: 6 }}>
            <Typography variant="h2" color={"#336699"}>
                Features
            </Typography>
            <Typography variant="h4" color={"#86BBD8"}>
                Available on all plans.
            </Typography>

            <Grid container spacing={3} sx={{ mt: 3 }}>
                {large_features.map((feature, idx) => (
                    <Grid item xs={12} sx={{ mb: 4 }}>
                        <LargeFeature feature={feature} idx={idx} />
                    </Grid>
                ))}
            </Grid>

            <Grid container spacing={3} sx={{ mt: 3 }}>
                {small_features.map((feature, idx) => (
                    <Grid item key={idx} xs={12} sm={6} md={4}>
                        <SmallFeature feature={feature} idx={idx} delay={large_features.length * 400} />
                    </Grid>
                ))}
            </Grid>
        </Container>
    );
}
