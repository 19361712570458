import { Container, Fade, Stack, Typography } from "@mui/material";
import { COLORS } from "../theme";

export default function ComingSoon() {
    return (
        <Container maxWidth="md" sx={{ pt: 8, textAlign: "center" }}>
            <Fade in timeout={750}>
                <Typography variant="h2" color={COLORS.LIGHT_GREEN} sx={{ mt: "25vh" }}>
                    coming soon
                </Typography>
            </Fade>
            <Stack direction={"column"} sx={{ my: 4 }}>
                <Typography variant="h5" color="secondary">
                    COMPASS
                </Typography>
                <Typography variant="body2" color="primary">
                    EVENT PLATFORM
                </Typography>
            </Stack>
        </Container>
    );
}
