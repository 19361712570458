import { createContext, Dispatch, useReducer } from "react";

export interface SiteState {
    title: string;
    hideFooter: boolean;
}

interface ContextProps {
    siteState: SiteState;
    setSiteState?: Dispatch<SiteState>;
}

export const SiteStateContext = createContext<ContextProps>({ siteState: { title: "", hideFooter: true } });

const SiteStateContextProvider = ({ children }: { children: any }) => {
    const [siteState, setSiteState] = useReducer((state: SiteState, newState: SiteState) => ({ ...state, ...newState }), {
        title: "",
        hideFooter: false,
    });

    return <SiteStateContext.Provider value={{ siteState, setSiteState }}>{children}</SiteStateContext.Provider>;
};

export default SiteStateContextProvider;
